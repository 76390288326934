<template>
  <v-container align-center justify-center>
    <v-layout row wrap justify-center>
      <v-img src="@/assets/images/logo.jpg" max-height="190" max-width="150">
      </v-img>
    </v-layout>
    <v-layout row wrap>
      <v-card class="mx-auto light" dark color="blue" max-width="800">
        <v-card-title>
          <v-icon large left> fab fa-wpforms </v-icon>
          <span class="title">Policies/Politicas</span>
        </v-card-title>

        <v-card-text
          dark
          class="light headline font-weight-bold text-xs-center"
        >
          <p>Select the order type</p>
          <p>Seleccione el tipo de orden</p>
        </v-card-text>

        <v-card-actions class="align-center justify-center">
          <v-btn color="light-green" @click="goToDelivery"
            >Delivery/Entrega
            <v-icon right>fas fa-plane-arrival</v-icon>
          </v-btn>

          <v-btn color="orange darken-2" @click="goToPickup"
            >Pick up/Recoger
            <v-icon right>fas fa-truck-loading</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: "actionType",
  data() {
    return {};
  },
  methods: {
    goToPickup() {
      this.$router.push({
        name: "policies",
        params: {
          type: "pickup",
        },
      });
    },
    goToDelivery() {
      this.$router.push({
        name: "policies",
        params: {
          type: "delivery",
        },
      });
    },
  },
};
</script>

<style>
.action {
  height: 90px;
  font-size: 1.3em;
}
.light {
  color: white !important;
}
</style>
